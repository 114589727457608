
import { Component, Prop, Vue } from 'vue-property-decorator'
import { IOrder } from '@/interfaces'
import { api } from '@/api'
import { dispatchGetDevices, dispatchGetOrders, dispatchSubmitUpdateOrder } from '@/store/main/actions'
import Spinner from '@/components/Spinner.vue'
import { readIsDev } from '@/store/main/getters'


@Component({
  components: {
    Spinner,
  },
})
export default class OrderItem extends Vue {
  @Prop({
    required: true,
  })
  public order!: IOrder

  serialNumber: string = ''
  status: string = 'activated' // '', 'valid', 'invalid', 'error', 'activated'
  loading: boolean = false

  serialNumberChecking: boolean = false
  deviceActivation: boolean = false

  get isDev() {
    return readIsDev(this.$store)
  }

  get inputSerialFormatted() {
    if (this.serialNumber === '') return '0000 0000 0000 0000'

    return this.serialNumber.replace(/(.{4})/g, '$1 ')
    // return this.serialNumber.replace(/(\d{4})/g, '$1 ')
  }

  inputSerial(order_id: number) {
    console.debug('%c inputSerial: %s', 'color:lime;', this.serialNumber, this.serialNumber.length, order_id)

    this.status = ''

    // let val = this.serialNumber.replace(/-/g, '')
    // console.debug('%c val replace:', 'color:lime;', val)
    // val.slice(0, 10)
    // val = val.substring(0, 16)
    // console.debug('%c val substring:', 'color:lime;', val)
    // this.serialNumber = val
    // console.debug('%c inputSerial: %s', 'color:gold;', this.serialNumber, this.serialNumber.length, order_id)

    if (this.serialNumber.length === 19) {
      console.debug('%c inputSerial: %s', 'color:orangered;', '16')
      this.validateSerial(this.serialNumber, order_id)
    }
  }

  async registerDevice(serial: string, order_id: number) {
    if (this.deviceActivation) return

    console.debug('%c registerDevice', 'color:lime;')

    // dispatchLoading(this.$store, true)
    // this.loading = true
    this.deviceActivation = true

    try {
      const data = {
        device_id: serial,
        order: order_id,
      }

      const result = await api.registerDevice(this.$store.state.main.token, data)

      console.debug('%c Dashboard.vue registerDevice result:', 'color:lime;font-size:36px;', result)

      if (result.status === 200) this.status = 'activated'

      this.serialNumber = ''

      // Перезапрашиваем заказы и устройства с сервера
      await dispatchGetOrders(this.$store)
      await dispatchGetDevices(this.$store)
    } catch (err) {
      // console.debug('%c Dashboard.vue validateSerial ERROR:', 'color:red;', err)
      console.debug('%c ERROR STATUS:', 'color:red;', err.response.status)

      // if (err.response.status === 400) this.status = 'invalid'
      // if (err.response.status === 403) this.status = 'error' // TODO REMOVE
      // if (err.response.status === 429) this.status = 'error'
    }

    // dispatchLoading(this.$store, false)
    // this.loading = false
    this.deviceActivation = false
  }

  async validateSerial(serial: string, order_id: number) {
    console.debug('%c validateSerial(serial: %s, order_id: %s)', 'color:gold;', serial, order_id)

    // dispatchLoading(this.$store, true)
    // this.loading = true
    this.serialNumberChecking = true

    try {
      const data = {
        device_id: serial,
        order: order_id,
      }

      const result = await api.validateSerial(this.$store.state.main.token, data)

      console.debug('%c OrderItem.vue validateSerial result:', 'color:lime;', result)

      if (result.status === 200) this.status = 'valid'
    } catch (err) {
      // console.debug('%c Dashboard.vue validateSerial ERROR:', 'color:red;', err)
      console.debug('%c ERROR STATUS:', 'color:red;', err.response.status)

      if (err.response.status === 400) this.status = 'invalid'
      if (err.response.status === 403) this.status = 'error' // TODO REMOVE
      if (err.response.status === 429) this.status = 'error'
    }

    // dispatchLoading(this.$store, false)
    // this.loading = false
    this.serialNumberChecking = false
  }

  getTrackURL(track_url: string, track_number: string) {
    return track_url.replace('{track_number}', track_number)
  }

  async handleDelivered(order: IOrder) {
    console.debug('%c handleDelivered(%s)', 'color:lime;', JSON.stringify(order, null, 4))

    this.loading = true;
    const response = await dispatchSubmitUpdateOrder(
      this.$store, {
        order: this.order.id,
        shipped: true,
      },
    );
    if (response && response.status) {
      console.debug('%c handleDelivered response: %s', 'color:gold;', JSON.stringify(response, null, 4))
      console.debug('%c STATUS: %s', 'color:magenta;', response.status)

      this.order.status = response?.status;
    } else {
      this.$toast.error(this.$t('Set status error').toString());
    }
    this.loading = false;
  }

  public handleOpenEditAddressModal(order: IOrder) {
    this.$emit('open-edit-address-modal', order)
  }

  public handleOpenPaymentModal(order: IOrder) {
    this.$emit('open-order-modal', order)
  }

}
