<template>
  <!-- class="popup popup-block popup-invoice" -->
 	 <section
        class="popup"
        :class="{ 'popup_active': modalShow }"
    >
        <!-- <button
            type="button"
            class="popup-block__close"
            @click="handleClose"
        ></button> -->

        <div v-if="!invoice">
            <div style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
                <spinner />
            </div>
        </div>

		<!-- class="popup-block__container popup-invoice__container" -->
        <div
            v-else
            class="popup__container popup__container_type_main popup-invoice__container"
        >
			<button
				type="button"
				class="popup__exit"
				@click="handleClose"
			></button>

            <div class="popup-invoice__content">
                <h2 class="popup-invoice__title">{{ $t('Invoice') }}</h2>
                <p class="popup-invoice__status">{{ $t('Payment expected') }}</p>

                <img
                  alt="qr"
                  class="popup-invoice__qr"
                  style="background-color: #fff;"
                  :src="invoice.qr_code"
                >

                <div class="popup-invoice__price-wrapper">
                    <p class="popup-invoice__price">
                        {{ toFixedWithoutRounding(invoice.crypto_amount) }} <span class="popup-invoice__price-currency">DEXNET</span>
                    </p>
                    <p class="popup-invoice__price-subtitle">{{ $t('Amount to be paid') }}</p>
                </div>

                <div class="popup-invoice__input-wrapper">
                    <p class="popup-invoice__type">
                        BEP-20
                    </p>
                    <label class="popup-invoice__label">
                        <textarea class="popup-invoice__textarea" readonly="readonly">{{ invoice.address }}</textarea>
                        <button
                            class="popup-invoice__copy"
                            type="button"
                            aria-label="Скопировать код"
                            @click="copyToClipboard(invoice.address)"
                        ></button>
                    </label>
                </div>

                <div class="popup-invoice__code-desc">
                    <p class="popup-invoice__code-title">{{ $t('Only BEP20') }}</p>
                    <p class="popup-invoice__code-text">
                        {{ $t('Make sure you are sending funds through the BEP-20 network, or change your preferred network above.') }}
                    </p>
                </div>
            </div>
            <div class="popup-invoice__desc">
                <p>{{ $t('Blockchain validators share 90% of all commissions among themselves. Nested blockchains are permanent as long as the wallet holds at least 30,000 DEXNET.') }}</p>
                <p>{{ $t('If the backing tokens are returned, the validator slot is freed and can be taken by another network participant.') }}</p>
            </div>
        </div>
    </section>
</template>
<script>
import { mapGetters } from 'vuex';
import { store } from '@/store';
import { dispatchGetPaymentStatus, dispatchGetOrders } from '@/store/main/actions';
import { readInvoiceResponse } from '@/store/main/getters';

import Spinner from '@/components/Spinner.vue'

export default {
    components: {
        Spinner
    },
    props: {
        modalShow: {
            type: Boolean,
            required: true
        },
        invoice: {
          type: Object,
          required: true
        }
    },
    data: () => ({
        updateTimeout: null
    }),
    conputed: {
        ...mapGetters({
            invoiceResponse: readInvoiceResponse
        })
    },
    methods: {
      toFixedWithoutRounding (n) {
        return n.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
      },
      handleClose() {
        if (this.updateTimeout) {
            clearTimeout(this.updateTimeout);
        }

        this.$emit('close-modal');
     },
    async getInvoiceStatus() {
      const invoiceStatusResponse = await dispatchGetPaymentStatus(store, { address: this.invoice.address, invoice: this.invoice.invoice.id });
      const invoiceDetail = invoiceStatusResponse.invoice;

        if (invoiceDetail && invoiceDetail.status == 'PAID') {
          this.$toast.success(this.$t('Order payment successful'), {
            duration: 3000, position: 'bottom',
          });

          this.handleClose();

          setTimeout(() => {
            window.location.reload();
          }, 2000)
        } else {
          this.updateTimeout = setTimeout(() => this.getInvoiceStatus(), 3000);
        }
      }
    },
    mounted() {
      this.getInvoiceStatus();
    }
}
</script>
<style scoped>
.popup-block__close {
    position: absolute;
    top: 16px;
    right: 16px;
    display: block;
    width: 32px;
    height: 32px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.19727 22.457C9.06836 22.334 8.9834 22.1875 8.94238 22.0176C8.90137 21.8477 8.90137 21.6777 8.94238 21.5078C8.98926 21.3379 9.07422 21.1943 9.19727 21.0771L14.5938 15.6631L9.19727 10.2578C9.07422 10.1406 8.99219 9.99707 8.95117 9.82715C8.91016 9.65723 8.91016 9.4873 8.95117 9.31738C8.99219 9.14746 9.07422 9.00098 9.19727 8.87793C9.32617 8.74902 9.47559 8.66406 9.64551 8.62305C9.81543 8.58203 9.98535 8.58203 10.1553 8.62305C10.3252 8.66406 10.4717 8.74609 10.5947 8.86914L16 14.2744L21.3965 8.86914C21.5195 8.74023 21.666 8.6582 21.8359 8.62305C22.0059 8.58203 22.1729 8.58203 22.3369 8.62305C22.5068 8.66406 22.6592 8.74902 22.7939 8.87793C22.917 9.00098 22.999 9.14746 23.04 9.31738C23.0869 9.4873 23.0869 9.65723 23.04 9.82715C22.999 9.99121 22.917 10.1377 22.7939 10.2666L17.3975 15.6631L22.7939 21.0684C22.917 21.1973 22.999 21.3467 23.04 21.5166C23.0811 21.6807 23.0811 21.8477 23.04 22.0176C22.999 22.1875 22.917 22.334 22.7939 22.457C22.665 22.5859 22.5156 22.6709 22.3457 22.7119C22.1758 22.7529 22.0059 22.7529 21.8359 22.7119C21.666 22.6709 21.5195 22.5889 21.3965 22.4658L16 17.0605L10.5947 22.4658C10.4717 22.5889 10.3252 22.6709 10.1553 22.7119C9.99121 22.7529 9.82129 22.7529 9.64551 22.7119C9.47559 22.6709 9.32617 22.5859 9.19727 22.457Z' fill='%2300D5C8'/%3E%3C/svg%3E%0A");
    transition: 0.3s ease-in-out;
}
.popup-invoice:before {
  content: "";
  display: block;
  height: 100%;
  width: 50%;
  position: absolute;
  top: 0;
  left: 0;
  background: #0b1414;
  z-index: -1;
}
@media (width <= 768px) {
  .popup-invoice:before {
    content: none;
  }
}
.popup-invoice__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 868px;
}
@media (width <= 768px) {
  .popup-invoice__container {
    grid-template-columns: 1fr;
  }
}
.popup-invoice__content {
  padding: 0 48px 0 32px;
}
@media (width <= 768px) {
  .popup-invoice__content {
    padding: 32px 0;
  }
}
.popup-invoice__desc {
  padding-left: 48px;
  padding-right: 32px;
  font-size: 16px;
  line-height: 150%;
  color: #fff;
  display: grid;
  align-items: start;
  row-gap: 16px;
  grid-template-rows: max-content;
}
@media (width <= 768px) {
  .popup-invoice__desc {
    padding: 32px 0;
  }
}
.popup-invoice__title {
  text-align: center;
  margin-bottom: 8px;
  font-size: 22px;
  color: #fff;
  font-family: "Eurostile Extd", sans-serif;
}
.popup-invoice__status {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
  color: #3571ff;
  column-gap: 4px;
  font-family: "Inter", sans-serif;
  margin-bottom: 28px;
}
.popup-invoice__status:before {
  content: "";
  display: block;
  width: 13px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='16' viewBox='0 0 13 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.1666 1.33337V3.33337C11.1666 5.9107 9.07725 8.00004 6.49992 8.00004M1.83325 1.33337V3.33337C1.83325 5.9107 3.92259 8.00004 6.49992 8.00004M6.49992 8.00004C9.07725 8.00004 11.1666 10.0894 11.1666 12.6667V14.6667M6.49992 8.00004C3.92259 8.00004 1.83325 10.0894 1.83325 12.6667V14.6667' stroke='%233571FF' stroke-width='1.5' /%3E%3Cpath d='M0.5 1.33337H12.5M12.5 14.6667H0.5' stroke='%233571FF' stroke-width='1.5' /%3E%3C/svg%3E");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.popup-invoice__qr {
  width: 140px;
  height: 140px;
  object-fit: contain;
  object-position: center;
  margin-bottom: 28px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.popup-invoice__price-wrapper {
  margin-bottom: 28px;
}
.popup-invoice__price {
  font-weight: 700;
  font-size: 32px;
  line-height: 1;
  text-align: center;
  color: #00fff0;
  font-family: "Eurostile Extd", sans-serif;
}
.popup-invoice__price-currency {
  font-size: 24px;
  color: #00fff0;
}
.popup-invoice__price-subtitle {
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  color: #8f8f95;
  font-family: "Inter", sans-serif;
}
.popup-invoice__input-wrapper {
  border: 1px solid #333;
  padding: 8px 12px 8px 8px;
  background: #212121;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  column-gap: 12px;
}
.popup-invoice__type {
  background: #2f2f2f;
  border: 1px solid #333;
  padding: 8px 12px 8px 8px;
  font-size: 12px;
  line-height: 133%;
  color: #fff;
  font-family: "Inter", sans-serif;
  display: flex;
  align-items: center;
  column-gap: 4px;
  flex-shrink: 0;
}
.popup-invoice__type:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.89547 6.72254L8.00325 3.61647L11.1123 6.72507L12.9196 4.91677L8.00325 -0.00012207L3.08691 4.91551L4.89547 6.72254ZM0 7.99988L1.8079 6.19224L3.61579 7.99988L1.8079 9.80751L0 7.99988ZM8.00578 12.3848L4.898 9.27751L3.08691 11.0833L3.08944 11.0858L8.00578 16.0002L12.9221 11.0833L12.9234 11.082L11.1148 9.27625L8.00578 12.3848ZM12.3838 7.99988L14.1917 6.19224L15.9996 7.99988L14.1917 9.80751L12.3838 7.99988ZM8.00117 6.16492L9.83501 7.99975H9.83627L9.83501 8.00101L8.00117 9.83584L6.16734 8.00354L6.16481 7.99975L6.16734 7.99722L6.48836 7.67625L6.64507 7.52082L8.00117 6.16492Z' fill='white' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-shrink: 0;
}
.popup-invoice__label {
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 12px;
}
.popup-invoice__textarea {
  display: block;
  width: 100%;
  background: transparent;
  appearance: none;
  font-size: 12px;
  line-height: 133%;
  color: #fff;
}
.popup-invoice__copy {
  width: 24px;
  height: 24px;
  display: block;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 22L9 9L22 9.00846L22 22L9 22Z' stroke='%2300FFF0' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' /%3E%3Cpath d='M17 9V2.00977L2 2L2 17H9' stroke='%2300FFF0' stroke-width='1.5' stroke-linejoin='round' /%3E%3C/svg%3E");
  background-position: center;
  background-size: contain;
  flex-shrink: 0;
  transition: 0.3s ease-in-out;
}
@media (hover: hover) {
  .popup-invoice__copy:hover {
    opacity: 0.7;
  }
}
@media (hover: none) {
  .popup-invoice__copy:active {
    opacity: 0.7;
  }
}
.popup-invoice__code-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: #fff;
  font-family: "Inter", sans-serif;
}
.popup-invoice__code-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 133%;
  color: #8f8f95;
  font-family: "Inter", sans-serif;
}
</style>