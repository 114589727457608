<template>
    <section
        class="popup"
        :class="{ 'popup_active': modalShow }"
    >
        <!-- <button
            type="button"
            class="popup-block__close"
            @click="handleClose"
        ></button> -->

        <div class="popup__container popup__container_type_main">
          <div class="popup-validation__container">
              <button
                type="button"
                class="popup__exit"
                @click="handleClose"
              ></button>
              <h2 class="popup-block__title">{{ $t('Activate Validation') }}</h2>

              <p class="popup-block__desc">{{ $t('Enter your key to become a blockchain validator') }}</p>

              <form action="#" class="popup-block__form">
                  <label class="popup-block__form-label">
                      <input
                          type="text"
                          class="popup-block__input"
                          v-model="activationKey"
                          :placeholder="$t('Validation key')"
                          :class="{ 'error-input': isError }"
                      >

                      <span
                          v-if="isError && errorMessage"
                          class="error-message"
                      >
                          {{ errorMessage }}
                      </span>
                  </label>

                  <button
                      class="popup-block__button"
                      type="button"
                      @click="handleSubmit"
                  >
                      {{ $t('Next') }}
                  </button>
              </form>
          </div>
        </div>
    </section>
</template>

<script>
import { store } from '@/store';
import { dispatchActivateValidator, dispatchCreateValidatorInvoice } from '@/store/main/actions';

export default {
    props: {
        modalShow: {
            type: Boolean,
            required: true
        },
        deviceId: {
            type: [String, Number],
            required: true
        }
    },
    data: () => ({
        activationKey: '',
        isError: false,
        errorMessage: ''
    }),
    methods: {
        handleSubmit() {
            if (!this.activationKey) {
                this.isError = true;
                this.errorMessage = this.$t('Invalid activation key');
            } else {
                this.isError = false;
                this.errorMessage = '';
            }

            if (!this.isError) {
                this.activateValidator();
            }
        },
        handleClose() {
            this.$emit('close-modal');
        },
        async activateValidator() {
            try {
                const response = await dispatchActivateValidator(store, {
                    device: this.deviceId,
                    activation_key: this.activationKey
                })

                console.log(response);

                if (response?.data?.invoice?.order) {
                    this.$emit('invoice-created', response?.data?.invoice?.order);
                } else {
                    this.isError = true;
                    this.errorMessage = this.$t('An error occurred');
                }
            } catch (error) {
                switch(error.response?.data?.error || '') {
                    case 'InsufficientBalance':
                      this.createInvoice()
                      break;
                    case 'InvalidDevice':
                      this.isError = true;
                      this.errorMessage = this.$t('Device not found');
                      break;
                    case 'InvalidActivationKey':
                      this.isError = true;
                      this.errorMessage = this.$t('Invalid activation key');
                      break;
                    case 'DoesNotValidatorAvailable':
                      this.isError = true;
                      this.errorMessage = this.$t('An error occurred');
                      break;
                    default:
                      this.isError = true;
                      this.errorMessage = this.$t('An error occurred');
                      break;
                }
            }
        },
        async createInvoice() {
            try {
                const response = await dispatchCreateValidatorInvoice(store, {
                    device: this.deviceId,
                    activation_key: this.activationKey
                })

                console.log(response);
                this.$emit('invoice-created', response?.data);
            } catch (error) {
                console.error(error);
                this.isError = true;
                this.errorMessage = this.$t('An error occurred');
            }
        }
    }
}
</script>

<style scoped>
.popup-block__container {
  padding: 0;
  margin: auto;
  width: 100%;
}
.popup-block__close {
  position: absolute;
  top: 16px;
  right: 16px;
  display: block;
  width: 32px;
  height: 32px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.19727 22.457C9.06836 22.334 8.9834 22.1875 8.94238 22.0176C8.90137 21.8477 8.90137 21.6777 8.94238 21.5078C8.98926 21.3379 9.07422 21.1943 9.19727 21.0771L14.5938 15.6631L9.19727 10.2578C9.07422 10.1406 8.99219 9.99707 8.95117 9.82715C8.91016 9.65723 8.91016 9.4873 8.95117 9.31738C8.99219 9.14746 9.07422 9.00098 9.19727 8.87793C9.32617 8.74902 9.47559 8.66406 9.64551 8.62305C9.81543 8.58203 9.98535 8.58203 10.1553 8.62305C10.3252 8.66406 10.4717 8.74609 10.5947 8.86914L16 14.2744L21.3965 8.86914C21.5195 8.74023 21.666 8.6582 21.8359 8.62305C22.0059 8.58203 22.1729 8.58203 22.3369 8.62305C22.5068 8.66406 22.6592 8.74902 22.7939 8.87793C22.917 9.00098 22.999 9.14746 23.04 9.31738C23.0869 9.4873 23.0869 9.65723 23.04 9.82715C22.999 9.99121 22.917 10.1377 22.7939 10.2666L17.3975 15.6631L22.7939 21.0684C22.917 21.1973 22.999 21.3467 23.04 21.5166C23.0811 21.6807 23.0811 21.8477 23.04 22.0176C22.999 22.1875 22.917 22.334 22.7939 22.457C22.665 22.5859 22.5156 22.6709 22.3457 22.7119C22.1758 22.7529 22.0059 22.7529 21.8359 22.7119C21.666 22.6709 21.5195 22.5889 21.3965 22.4658L16 17.0605L10.5947 22.4658C10.4717 22.5889 10.3252 22.6709 10.1553 22.7119C9.99121 22.7529 9.82129 22.7529 9.64551 22.7119C9.47559 22.6709 9.32617 22.5859 9.19727 22.457Z' fill='%2300D5C8'/%3E%3C/svg%3E%0A");
  transition: 0.3s ease-in-out;
}
@media (hover: hover) {
  .popup-block__close:hover {
    opacity: 0.7;
  }
}
@media (hover: none) {
  .popup-block__close:active {
    opacity: 0.7;
  }
}
.popup-block__title {
  text-align: center;
  font-weight: 600;
  font-size: 32px;
  color: #daf1f0;
  font-family: var(--accent-font-alt), sans-serif;
  margin-bottom: 8px;
}
.popup-block__desc {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: rgba(162, 220, 217, 0.6);
  margin-bottom: 24px;
}
.popup-block__form {
  display: grid;
  gap: 21px;
}
.popup-block__form-label {
  overflow: auto;
  width: 100%;
}
.popup-block__input {
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 12px;
  font-size: 16px;
  background: rgba(126, 206, 202, 0.1);
  display: block;
  width: 100%;
  font-family: var(--accent-font), sans-serif;
}
.popup-block__input:-moz-placeholder {
  color: rgba(162, 220, 217, 0.35);
  font-size: inherit;
  line-height: normal;
}
.popup-block__input::-moz-placeholder {
  color: rgba(162, 220, 217, 0.35);
  font-size: inherit;
  line-height: normal;
}
.popup-block__input::-webkit-input-placeholder {
  color: rgba(162, 220, 217, 0.35);
  font-size: inherit;
  line-height: normal;
}
.popup-block__input:-ms-input-placeholder {
  color: rgba(162, 220, 217, 0.35);
  font-size: inherit;
  line-height: normal;
}
.popup-block__button {
  font-weight: 600;
  font-size: 16px;
  line-height: 137%;
  text-align: center;
  color: #0e1f1e;
  font-family: var(--accent-font), sans-serif;
  background: linear-gradient(180deg, #02e4c9 0%, #00cece 100%);
  border-radius: 55px;
  padding: 12px 20px;
  transition: 0.3s ease-in-out;
}
@media (hover: hover) {
  .popup-block__button:hover {
    opacity: 0.8;
  }
}
@media (hover: none) {
  .popup-block__button:active {
    opacity: 0.8;
  }
}

.popup-validation__container {
  max-width: 300px;
}
@media (width <= 415px) {
  .popup-validation__container {
    max-width: 100%;
  }
}

.popup-validation__container {
  max-width: 300px;
}
@media (width <= 415px) {
  .popup-validation__container {
    max-width: 100%;
  }
}

.error-input {
  border: 1px solid #fe4d58;
}

.error-message {
  font-weight: 400;
  font-size: 14px;
  color: #fe4d58;
  font-family: var(--accent-font), sans-serif;
  display: block;
  margin-top: 2px;
  height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}
</style>