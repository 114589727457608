
import { Bar } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js'
import { Component, Prop, Vue } from 'vue-property-decorator'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
)

@Component({
  components: { Bar },
})
export default class BarChart extends Vue {
  @Prop({})
  chartData!: any

  public chartOptions = {
    responsive: true,
    showLine: true,
    scales: {
      yAxes: [{
        display: true,
        ticks: {},
      }],
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart - Legend',
    }
  }
  mounted() {
    console.debug('%c BarChart.vue is mounted!', 'color:magenta;font-size:36px;', ChartJS)
  }
}
