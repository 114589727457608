
import { dispatchGetOrders, dispatchSubmitUpdateOrder } from '@/store/main/actions';
import { Component, Vue , Prop} from 'vue-property-decorator';
import Spinner from '@/components/Spinner.vue'
import { IOrder } from '@/interfaces'

@Component({
  components: {
    Spinner,
  },
})
export default class EditDeliveryAddressModal extends Vue {
  @Prop({required: true, default: false})
  public modalShow!: boolean;

  @Prop({required: true})
  public order!: IOrder;

  public loadingState: any = {
    submit: false,
  };
  public country: string = '';
  public city: string = '';
  public deliveryAddress: string = '';
  public formError: boolean = false;

  public latinRegex = /^[a-zA-Z\s-]*$/
  public latinReplaceRegex = /[^a-zA-Z\s-]/g;
  public addressRegex = /^[a-zA-Z0-9\s#"'()+-:]*$/;
  public addressReplaceRegex = /[^a-zA-Z0-9\s#"'()+-:]/g;

  public mounted() {
    this.country = this.order.country;
    this.city = this.order.city;
    // this.deliveryAddress = this.order.delivery_address;
  }

  public validateForm() {
    if (
      !this.country
      || !this.city
      // || !this.deliveryAddress
    ) {
      this.formError = true;
    } else {
      this.formError = false;
    }
    return !this.formError;
  }

  public checkCountry() {
    const country = this.country
    if (!this.latinRegex.test(country)) {
      this.country = country.replace(this.latinReplaceRegex, '')
    }
  }

  public checkCity() {
    const city = this.city
    if (!this.latinRegex.test(city)) {
      this.city = city.replace(this.latinReplaceRegex, '')
    }
  }

  public checkAddress() {
    const address = this.deliveryAddress;

    if (!this.addressRegex.test(address)) {
      this.deliveryAddress = address.replace(this.addressReplaceRegex, '');
    }
  }

  public async handleSubmit() {
    if (await this.validateForm()) {
      this.loadingState.submit = true;
      const response = await dispatchSubmitUpdateOrder(
        this.$store, {
          order: this.order.id,
          country: this.country,
          city: this.city,
          delivery_address: '_', // this.deliveryAddress,
        },
      );
      if (response) {
        await dispatchGetOrders(this.$store);
        this.$toast.success(this.$t('Saved successfully').toString());
        this.closeModal();
      } else {
        this.$toast.error(this.$t('Error').toString());
      }
      this.loadingState.submit = false;
    }
  }

  public closeModal() {
    this.$emit('close-modal');
  }
}
