
import { Line as CustomLine } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from 'chart.js'
import { Component, Prop, Vue } from 'vue-property-decorator'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
)

@Component({
  components: { CustomLine },
})
export default class LineChart extends Vue {
  @Prop({})
  chartData!: any

  chartOptions = {
    responsive: true,
    // showLine: true,
    scales: {
      yAxes: [{
        display: true,
        ticks: {
          beginAtZero: true,
          suggestedMin: 0,
          stepSize: 20,
        },
      }],
    },
  }
  mounted() {
    console.debug('%c LineChart.vue is mounted!', 'color:lime;font-size:36px;', ChartJS)
  }
}
