
import { IInvoice, IOrder } from '@/interfaces';
import { dispatchGetOrCreateInvoice, dispatchGetOrders, dispatchGetPaymentStatus } from '@/store/main/actions';
import { readInvoiceResponse } from '@/store/main/getters';
import { commitSetInvoiceResponse } from '@/store/main/mutations';
import { Component, Vue , Prop} from 'vue-property-decorator';
import Spinner from '@/components/Spinner.vue'

@Component({
  components: {
    Spinner,
  },
})
export default class OrderPaymentModal extends Vue {
  @Prop({
    required: true,
    default: false,
  })
  public modalShow!: boolean;

  @Prop({
    required: true,
    default: null,
  })
  public order!: IOrder;

  public loadingState: any = {
    standart: false,
  };
  public standart: string = '';
  public invoiceDetail: IInvoice | null = null;
  public transaction: any = null;
  public progressPercent = 100;
  public timeToReload = 15;
  public isModalClosed: boolean = false;

  public async mounted() {
    commitSetInvoiceResponse(this.$store, null);
    // await this.handleChangeMethod('TRC20');
    await this.handleChangeMethod('BEP20');
    this.updateProgressBar();
  }

  public async handleChangeMethod(standart: string) {
    if (this.standart != standart) {
      this.loadingState.standart = true;
      this.standart = standart;
      await dispatchGetOrCreateInvoice(this.$store, {order: this.order.id, standart: this.standart, token: 'USDT' });
      if (this.invoiceResponse && this.invoiceResponse.invoice) {
        this.invoiceDetail = this.invoiceResponse.invoice;
      }
      this.progressPercent = 100;
      this.timeToReload = 15;
      this.loadingState.standart = false;
    }
  }

  public refreshTimer() {
    this.progressPercent = 0;
    this.timeToReload = 0;
  }

  public async updateProgressBar() {
    if (this.isModalClosed) {
      return;
    }
    if (this.invoiceDetail && this.invoiceDetail.status === 'WAIT_PAYMENT') {
      this.progressPercent = this.progressPercent - 6.66;
      this.timeToReload = this.timeToReload - 1;
      if (this.progressPercent > 0) {
        setTimeout(() => this.updateProgressBar(), 1000);
      } else {
        await this.updatePaymentInfo();
      }
    } else if (this.invoiceDetail && this.invoiceDetail.status !== 'WAIT_PAYMENT') {
      await dispatchGetOrders(this.$store);
      // @ts-ignore
      this.$toast.success(this.$t('Order payment successful'), {
        duration: 3000, position: 'bottom',
      });
      this.closeModal();
    }
  }

  public async updatePaymentInfo() {
    if (this.invoiceResponse && this.invoiceDetail) {
      const paymentResponse = await dispatchGetPaymentStatus(
        this.$store, {address: this.invoiceResponse.address, invoice: this.invoiceDetail.id},
      );
      if (paymentResponse) {
        this.invoiceDetail = paymentResponse.invoice;
        this.transaction = paymentResponse.transaction;
      }
      this.progressPercent = 100;
      this.timeToReload = 15;
      this.updateProgressBar();
    }
  }

  public closeModal() {
    this.isModalClosed = true;
    this.$emit('close-modal');
  }

  get invoiceResponse() {
    return readInvoiceResponse(this.$store);
  }
}
