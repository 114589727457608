
import { Component, Vue } from 'vue-property-decorator'
import Spinner from '@/components/Spinner.vue'
// import LineChart from '@/components/chart/LineChart.vue'

@Component({
  components: { Spinner },
  // components: {
  //   LineChart,
  // },
})
export default class RandomChart extends Vue {
  dataCollection: any = null
  loading: boolean = false

  getRandomInt () {
    return Math.floor(Math.random() * (50 - 5 + 1)) + 5
  }

  fillData() {
    this.dataCollection = {
      labels: [this.getRandomInt(), this.getRandomInt()],
      datasets: [
        {
          label: 'Data One',
          backgroundColor: '#f87979',
          data: [this.getRandomInt(), this.getRandomInt()]
        }, {
          label: 'Data One',
          backgroundColor: '#f87979',
          data: [this.getRandomInt(), this.getRandomInt()]
        }
      ]
    }
  }

  mounted() {
    console.debug(JSON.stringify(this.dataCollection))
    this.fillData()
    console.debug(JSON.stringify(this.dataCollection))
  }


  // public chartData: any = null
  // public intervalId: any = null
  // public interval: number = 10000
  //
  // public async getChartData() {
  //   const { data } = await api.getChartData(this.$store.state.main.token)
  //   if (data) {
  //     this.chartData = data
  //   }
  // }
  //
  // public async mounted() {
  //   await this.getChartData()
  //   this.intervalId = setInterval(async () => {
  //     await this.getChartData()
  //   }, this.interval)
  // }
  //
  // public beforeDestroy() {
  //   clearInterval(this.intervalId)
  // }
}
