
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class Countdown extends Vue {
  @Prop({
    required: false,
    default: 0,
  })
  private time!: number

  // VARIABLES
  private SEC = 1000
  private MIN = this.SEC * 60
  private HOUR = this.MIN * 60
  private DAY = this.HOUR * 24
  private MONTH = this.DAY * 30
  private YEAR = this.MONTH * 12
  // VARIABLES

  public intervalId: any = null
  public delay: number = this.SEC

  public dic: any = {
    ru: {
      // ago: 'назад',
      ago: '',
      sec: ['секунда', 'секунды', 'секунд'],
      min: ['минута', 'минуты', 'минут'],
      hour: ['час', 'часа', 'часов'],
      day: ['день', 'дня', 'дней'],
      mon: ['месяц', 'месяца', 'месяцев'],
    },
    en: {
      // ago: 'ago',
      ago: '',
      sec: 'second',
      min: 'minute',
      hour: 'hour',
      day: 'day',
      mon: 'month',
    },
  }

  get getSessionTime() {
    return this.formatToLongString(this.delta, this.type)
  }

  get lng() {
    return this.$i18n.locale || 'en'
  }

  public sessionTime: string = ''
  public delta: number = 0
  public type: string = 'sec'

  public formatToLongString(time, type) {
    console.debug('%c formatToLongString(time: %s, type: %s)', 'color:gold;', time, type)
    const dic = this.dic[this.lng][type]

    if (this.lng === 'ru') {
      const ago = this.dic.ru.ago
      // RUSSIAN
      let _time = time % 100

      if (_time > 19) {
        _time = _time % 10
      }

      switch (_time) {
        case 1: return ` ${time} ${dic[0]} ${ago}`
        case 2:
        case 3:
        case 4: return ` ${time} ${dic[1]} ${ago}`
        default: return ` ${time} ${dic[2]} ${ago}`
      }
    }

    // ENGLISH
    const ago = this.dic.en.ago
    return `${time} ${dic}${time > 1 ? 's' : ''} ${ago}`
  }

  countDelta() {
    if (this.time) {
      const delta = Date.now() - this.time

      if (delta < this.MIN) {
        this.delta = Math.floor(delta / this.SEC)
        this.type = 'sec'
      } else if (delta < this.HOUR) {
        this.delta = Math.floor(delta / this.MIN)
        this.type = 'min'
      } else if (delta < this.DAY) {
        this.delta = Math.floor(delta / this.HOUR)
        this.type = 'hour'
      } else if (delta < this.MONTH) {
        this.delta = Math.floor(delta / this.DAY)
        this.type = 'day'
      } else {
        this.delta = Math.floor(delta / this.MONTH)
        this.type = 'mon'
      }
    } else {
      this.sessionTime = ''
    }
  }

  startCount() {
    // console.debug('%c startCount', 'color:lime;font-size:24px;')
    this.intervalId = setInterval(() => {
      const delta = Date.now() - this.time
      this.countDelta()

      if (delta >= this.MIN && this.delay === this.SEC) this.startLazyCount()
    }, this.delay)
  }

  startLazyCount() {
    // console.debug('%c startLazyCount', 'color:gold;font-size:24px;')
    clearInterval(this.intervalId)
    this.delay = this.MIN
    this.startCount()
  }

  mounted() {
    // console.debug('%c Countdown.vue is mounted! time: %s lng: %s', 'color:lime;font-size:18px;', this.time, this.lng)
    this.startCount()
  }

  beforeDestroy() {
    clearInterval(this.intervalId)
  }
}
