
import { Component, Prop, Vue } from 'vue-property-decorator'
import { readLoading } from '@/store/main/getters'

@Component({})
export default class Loader extends Vue {
  get isLoading() {
    return readLoading(this.$store)
  }
}
